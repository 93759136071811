:root {
  /* Earth Mode variables */
  --earth-bg: #242925; /* Light green background */
  --earth-text: #f6f5f5; /* Dark brown text */
  --earth-heading: #87ceeb; /* Dark green heading text */
  --earth-button-bg: transparent; /* Dark brown button background */
  --earth-button-text: #6ba53e; /* Light green button text */

  /* Light Mode variables */
  --light-bg: #fff; /* Light gray background */
  --light-text: #333; /* Dark gray text */
  --light-heading: #70ad53; /* Bright blue heading text */
  --light-button-bg: transparent; /* Bright blue button background */
  --light-button-text: #70ad53; /* White button text */
}

[data-theme="earth"] {
  --primary-bg: var(--earth-bg);
  --primary-text: var(--earth-text);
  --heading-color: var(--earth-heading);
  --button-bg: var(--earth-button-bg);
  --button-text: var(--earth-button-text);
}

[data-theme="light"] {
  --primary-bg: var(--light-bg);
  --primary-text: var(--light-text);
  --heading-color: var(--light-heading);
  --button-bg: var(--light-button-bg);
  --button-text: var(--light-button-text);
}

.container {
  background-color: var(--primary-bg);
  color: var(--primary-text);
}

.container h1 {
  color: var(--heading-color);
}

.container button {
  background-color: var(--button-bg);
  color: var(--button-text);
  border: none;
  padding: 5px;
  cursor: pointer;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.container button:hover {
  background-color: #dfe7dc; /* You can also make this a variable */
  color: #fff; /* You can also make this a variable */
}
