.about-section {
    padding: 60px 0;
  }
  
  .about-section h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .about-section p {
    font-size: 1.2em;
    line-height: 1.6;
  }
  
  .about-section .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .about-section h2 {
      font-size: 2em;
    }
    .about-section p {
      font-size: 1em;
    }
  }
  