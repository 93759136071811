.ticker-container1 {
    overflow: hidden;
    background-color: transparent;
    
  }
  
  .ticker-wrapper1 {
    display: inline-block;
    animation: ticker 15s linear infinite; /* Adjust the time as needed */
    white-space: nowrap;
    width: 200%; /* This is because we're duplicating the items */
}
  
  .ticker-item {
    display: inline-block;
    font-weight: light;
    padding: 0 5px;
  }
  .ticker-link {
    text-decoration: none;
    color: inherit; 
  }
  
  
  @keyframes ticker {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}