.waves {
    color: transparent;
    -webkit-text-stroke: 1px #eff5f4;
    position: relative;
}
.waves:after{
    content: attr(data-word);
    position: absolute;
    top: 0;
    left: 0;
    color: #a2e498;
    animation: waves 2s ease-in-out infinite forwards;
}
@keyframes waves{
    0%, 100% {
        clip-path: polygon(0 66%, 11% 59%, 18% 51%, 26% 46%, 35% 41%, 48% 44%, 55% 54%, 63% 63%, 76% 60%, 82% 50%, 92% 48%, 100% 53%, 100% 100%, 0% 100%);
    }
    50% {
        clip-path: polygon(0 66%, 8% 74%, 17% 77%, 28% 70%, 35% 57%, 48% 44%, 56% 39%, 69% 41%, 75% 47%, 84% 60%, 92% 61%, 100% 53%, 100% 100%, 0% 100%);
    }
}
  