body {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica', sans-serif;
    background-color: #f8fdff;
    color: #12161a;
    width: '50%';
    height: '50%';
    margin: 0;
    padding: 5;
}

html {
    background-color: rgba(248,253,255,1);    
}

#letter {
    font-size: 120px;
    font-family: Helvetica;
    font-weight: Bold;
    color: rgba(18,22,26,0.7);
    width: 120px;
    height: 120px;
    text-align: center;
    vertical-align: middle;
    line-height: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -60px;
    margin-left: -60px;
    z-index: -1;
}

#description {
    position: fixed;
    bottom: 10px;
    width: 100%;
    z-index: 100;
    font-family: Helvetica;
    font-weight: 200;
    color: rgba(18,22,26,0.7);
    text-align: center;
    font-size: 11px;
    letter-spacing: 1px;
}

#animation {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 10px;
}

.filter {
    animation: filter 8s cubic-bezier(.48,-0.33,.55,1.31) 0s infinite normal none;
  -webkit-animation: filter 8s cubic-bezier(.48,-0.33,.55,1.31) 0s infinite normal none;
    width: 30px;
    height: 20px;
    border-radius: 5px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: rgba(218,216,212,1);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top:30px;
    margin-left: 60px;
    z-index: 1;
}

.filter:after {
    content:"";
    background-color: rgba(60,56,52,1);
    width: 65px;
    height: 10px;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    position: absolute;
    margin-left: 26px;
    margin-top: 3px;
    z-index: -10;
}

.filter .espresso {
    background-color: rgba(60,56,52,0.8);
    animation: espresso 8s ease 0s infinite normal none;
  -webkit-animation: espresso 8s ease 0s infinite normal none;
    position: absolute;
    width: 3px;
    height: 18px;
    top: 23px;
    left: 13px;
}

.filter .spigot {
    position: absolute;
    width: 5px;
    height: 6px;
    background-color: rgba(60,56,52,1);
    margin-left: 12px;
    margin-top: 18px;
    z-index: -1;
}

.grounds {
    width: 50px;
    height: 96px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: 180px;
    margin-top: -45px;
    background-color: rgba(144,255,205,1);
    border-top: 4px solid rgba(60,56,52,0.6);
    border-radius: 3px;
    animation: grounds 8s cubic-bezier(.48,-0.33,.55,1.31) 0s infinite normal none;
  -webkit-animation: grounds 8s cubic-bezier(.48,-0.33,.55,1.31) 0s infinite normal none;
}

.grounds .contents {
    width: 10px;
    height: 40px;
    margin-top: -43px;
    margin-left: 10px;
    overflow: hidden;
    position: absolute;
    animation: groundsPour 8s ease-in-out 0s infinite normal none;
  -webkit-animation: groundsPour 8s ease-in-out 0s infinite normal none;
}

.grounds .contents div {
    position: absolute;
    animation: groundsPourGrounds 8s cubic-bezier(.48,-0.33,.55,1.31) 0s infinite normal none;
  -webkit-animation: groundsPourGrounds 8s cubic-bezier(.48,-0.33,.55,1.31) 0s infinite normal none;
    width: 40px;
    height: 20px;
    bottom: 0;
    right: 0;
    margin-left: 5px;
    border-top: 5px solid rgba(60,56,52,1);
    border-right: 5px solid rgba(60,56,52,1);
    border-top-right-radius: 20px;
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    transform: rotate(10deg);
}

.grounds .label {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 40%;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    background-color: rgba(255,255,255,0.9);
    border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    line-height: 30px;
    text-align: center;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.grounds .label span {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    color: rgba(60,56,52,0.6);
    margin-left: -5px;
    font-family: Courier;
    position: absolute;
}

.kettle {
    position: absolute;
    animation: kettle 8s cubic-bezier(.48,-0.33,.55,1.31) 0s infinite normal none;
  -webkit-animation: kettle 8s cubic-bezier(.48,-0.33,.55,1.31) 0s infinite normal none;
    left: 50%;
    top: 50%;
    margin-top: -45px;
    margin-left: 60px;
    width: 80px;
    height: 60px;
}

.kettlecontents {
    animation: waterPour 8s ease-in-out 0s infinite normal none;
  -webkit-animation: waterPour 8s ease-in-out 0s infinite normal none;
    z-index: -1;
    width: 60px;
    height: 60px;
    overflow: hidden;
    position: absolute;
    margin-left: -13px;
    margin-top: -135px;
    top: 50%;
    left: 50%;
}

.kettlecontents div {
    position: absolute;
    right: 0;
    top: 5px;
    width: 50px;
    height: 50px;
    animation: water 8s ease-in-out 0s infinite normal none;
  -webkit-animation: water 8s ease-in-out 0s infinite normal none;
    border-top: 10px solid rgba(2,125,255,0.7);
    border-left: 10px solid rgba(2,125,255,0.7);
    border-top-left-radius: 50px;
    z-index: -1;
}

.kettle:before {
    content:"";
    position: absolute;
    left: 0%;
    top: 0%;
    width: 80px;
    height: 60px;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgba(218,216,212,1);
    border-bottom: 5px solid rgba(60,56,52,1);
}

.kettle .spout {
    width: 12px;
    border-bottom: 30px solid rgba(218,216,212,1);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    position: absolute;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin-top: -5px;
    margin-left: -5px;
}

.kettle .handle {
    width: 40px;
    height: 40px;
    position: absolute;
    border-radius: 40px;
    border: 8px solid rgba(60,56,52,1);
    z-index: -1;
    margin-left: 45px;
    margin-top:-5px;
}

.kettle .lid {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: rgba(60,56,52,1);
    left: 35px;
    top: -6px;
    z-index: -1;
}

.cup {
    width: 25px;
    height: 20px;
    background-color: rgba(255,74,83,1);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 25px;
    margin-left: -12px;
}

.machine {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 125px;
    height: 160px;
    margin-top: -80px;
    margin-left: -200px;
    animation: machineBody 8s ease-in-out 0s infinite normal none;
  -webkit-animation: machineBody 8s ease-in-out 0s infinite normal none;
}

.machine .base {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 25px;
    background-color: rgba(60,56,52,1);
    border-top: 10px solid rgba(218,216,212,1);
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.machine .status {
    position: absolute;
    top: 23px;
    left: 18px;
    z-index: 10;
}

.machine .status div {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    margin-bottom: 3px;
}

.machine .status .on {
    background-color: rgba(134,255,195,1);
    animation: greenlight 8s cubic-bezier(.48,-0.33,.55,1.31) 0s infinite normal none;
  -webkit-animation: greenlight 8s cubic-bezier(.48,-0.33,.55,1.31) 0s infinite normal none;
}

.machine .status .off {
    background-color: rgba(255,74,83,1);
    animation: redlight 8s cubic-bezier(.48,-0.33,.55,1.31) 0s infinite normal none;
  -webkit-animation: redlight 8s cubic-bezier(.48,-0.33,.55,1.31) 0s infinite normal none;
}

.machine .verticalStructure {
    position: absolute;
    bottom: 25px;
    border-left: 20px solid rgba(60,56,52,1);
    border-top: 10px solid rgba(60,56,52,1);
    width: calc(100% - 20px);
    height: 80px;
    z-index: 1;
}

.machine .head {
    position: absolute;
    background-color: rgba(218,216,212,1);
    border-top: 10px solid rgba(60,56,52,1);
    width: 100%;
    height: 30px;
    bottom: 0;
    margin-bottom: 115px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.machine .lid {
    position: absolute;
    top:0;
    left: 50%;
    width: 60px;
    height: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: rgba(60,56,52,1);
    margin-top: 0px;
    margin-left: -30px;
    -webkit-transform-origin: 2px center;
    transform-origin: 2px center;
    animation: machineLid 8s cubic-bezier(.48,-0.33,.55,1.31) 0s infinite normal none;
  -webkit-animation: machineLid 8s cubic-bezier(.48,-0.33,.55,1.31) 0s infinite normal none;
}

.machine .steamer {
    position: absolute;
    width: 60px;
    height: 15px;
    border-right: 5px solid rgba(218,216,212,1);
    border-top: 5px solid rgba(218,216,212,1);
    border-top-right-radius: 10px;
    margin-top: 30px;
    margin-left: 90px;
}

.machine .back {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    position: absolute;
    background-color: rgba(60,56,52,1);
    margin-top: 26px;
    margin-left: -20px;
    z-index: -1;
}

.machine .spout {
    width: 40px;
    position: absolute;
    border-top: 10px solid rgba(60,56,52,1);
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    margin-top: 55px;
    margin-left: 32px;
}

.machine .steamer:after {
    content: ".";
    width: 20px;
    height: 25px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    background-color: rgba(60,56,52,1);
    margin-left: 53px;
    margin-top: 5px;
    position: absolute;
    -webkit-transform: scale( 0.5 , 1 );
    -moz-transform: scale( 0.5 , 1 );
    -ms-transform: scale( 0.5 , 1 );
    -o-transform: scale( 0.5 , 1 );
    transform: scale( 0.5 , 1 );
}

@keyframes kettle {
  100% { } 
  18% { -webkit-transform: translate( 0px , -120px ) rotate(0deg); -moz-transform: translate( 0px , -120px ) rotate(0deg); -ms-transform: translate( 0px , -120px ) rotate(0deg); -o-transform: translate( 0px , -120px ) rotate(0deg); transform: translate( 0px , -120px ) rotate(0deg);} 
  90% { -webkit-transform: rotate(0deg) translate( 0px , 0px ); -moz-transform: rotate(0deg) translate( 0px , 0px ); -ms-transform: rotate(0deg) translate( 0px , 0px ); -o-transform: rotate(0deg) translate( 0px , 0px ); transform: rotate(0deg) translate( 0px , 0px );} 
  86% { -webkit-transform: translate( 0px , -120px ) rotate(0deg); -moz-transform: translate( 0px , -120px ) rotate(0deg); -ms-transform: translate( 0px , -120px ) rotate(0deg); -o-transform: translate( 0px , -120px ) rotate(0deg); transform: translate( 0px , -120px ) rotate(0deg);} 
  41% { -webkit-transform: translate( 0px , -120px ) rotate(0deg); -moz-transform: translate( 0px , -120px ) rotate(0deg); -ms-transform: translate( 0px , -120px ) rotate(0deg); -o-transform: translate( 0px , -120px ) rotate(0deg); transform: translate( 0px , -120px ) rotate(0deg);} 
  14% { -webkit-transform: rotate(0deg) translate( 0px , 0px ); -moz-transform: rotate(0deg) translate( 0px , 0px ); -ms-transform: rotate(0deg) translate( 0px , 0px ); -o-transform: rotate(0deg) translate( 0px , 0px ); transform: rotate(0deg) translate( 0px , 0px );} 
  37% { -webkit-transform: translate( 0px , -120px ) rotate(-45deg); -moz-transform: translate( 0px , -120px ) rotate(-45deg); -ms-transform: translate( 0px , -120px ) rotate(-45deg); -o-transform: translate( 0px , -120px ) rotate(-45deg); transform: translate( 0px , -120px ) rotate(-45deg);} 
  22% { -webkit-transform: translate( 0px , -120px ) rotate(-45deg); -moz-transform: translate( 0px , -120px ) rotate(-45deg); -ms-transform: translate( 0px , -120px ) rotate(-45deg); -o-transform: translate( 0px , -120px ) rotate(-45deg); transform: translate( 0px , -120px ) rotate(-45deg);} 
  0% { -webkit-transform: rotate(0deg) translate( 0px , 0px ); -moz-transform: rotate(0deg) translate( 0px , 0px ); -ms-transform: rotate(0deg) translate( 0px , 0px ); -o-transform: rotate(0deg) translate( 0px , 0px ); transform: rotate(0deg) translate( 0px , 0px );} 
}

@-webkit-keyframes kettle {
  100% { } 
  18% { -webkit-transform: translate( 0px , -120px ) rotate(0deg); -moz-transform: translate( 0px , -120px ) rotate(0deg); -ms-transform: translate( 0px , -120px ) rotate(0deg); -o-transform: translate( 0px , -120px ) rotate(0deg); transform: translate( 0px , -120px ) rotate(0deg);} 
  90% { -webkit-transform: rotate(0deg) translate( 0px , 0px ); -moz-transform: rotate(0deg) translate( 0px , 0px ); -ms-transform: rotate(0deg) translate( 0px , 0px ); -o-transform: rotate(0deg) translate( 0px , 0px ); transform: rotate(0deg) translate( 0px , 0px );} 
  86% { -webkit-transform: translate( 0px , -120px ) rotate(0deg); -moz-transform: translate( 0px , -120px ) rotate(0deg); -ms-transform: translate( 0px , -120px ) rotate(0deg); -o-transform: translate( 0px , -120px ) rotate(0deg); transform: translate( 0px , -120px ) rotate(0deg);} 
  41% { -webkit-transform: translate( 0px , -120px ) rotate(0deg); -moz-transform: translate( 0px , -120px ) rotate(0deg); -ms-transform: translate( 0px , -120px ) rotate(0deg); -o-transform: translate( 0px , -120px ) rotate(0deg); transform: translate( 0px , -120px ) rotate(0deg);} 
  14% { -webkit-transform: rotate(0deg) translate( 0px , 0px ); -moz-transform: rotate(0deg) translate( 0px , 0px ); -ms-transform: rotate(0deg) translate( 0px , 0px ); -o-transform: rotate(0deg) translate( 0px , 0px ); transform: rotate(0deg) translate( 0px , 0px );} 
  37% { -webkit-transform: translate( 0px , -120px ) rotate(-45deg); -moz-transform: translate( 0px , -120px ) rotate(-45deg); -ms-transform: translate( 0px , -120px ) rotate(-45deg); -o-transform: translate( 0px , -120px ) rotate(-45deg); transform: translate( 0px , -120px ) rotate(-45deg);} 
  22% { -webkit-transform: translate( 0px , -120px ) rotate(-45deg); -moz-transform: translate( 0px , -120px ) rotate(-45deg); -ms-transform: translate( 0px , -120px ) rotate(-45deg); -o-transform: translate( 0px , -120px ) rotate(-45deg); transform: translate( 0px , -120px ) rotate(-45deg);} 
  0% { -webkit-transform: rotate(0deg) translate( 0px , 0px ); -moz-transform: rotate(0deg) translate( 0px , 0px ); -ms-transform: rotate(0deg) translate( 0px , 0px ); -o-transform: rotate(0deg) translate( 0px , 0px ); transform: rotate(0deg) translate( 0px , 0px );} 
}

@keyframes machineBody {
  100% { margin-left: -200px;} 
  90% { margin-left: -200px;} 
  86% { margin-left: -62px;} 
  18% { margin-left: -62px;} 
  14% { margin-left: -200px;} 
  0% { margin-left: -200px;} 
}

@-webkit-keyframes machineBody {
  100% { margin-left: -200px;} 
  90% { margin-left: -200px;} 
  86% { margin-left: -62px;} 
  18% { margin-left: -62px;} 
  14% { margin-left: -200px;} 
  0% { margin-left: -200px;} 
}

@keyframes machineLid {
  100% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -ms-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg);} 
  43% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -ms-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg);} 
  39% { -webkit-transform: rotate(-105deg); -moz-transform: rotate(-105deg); -ms-transform: rotate(-105deg); -o-transform: rotate(-105deg); transform: rotate(-105deg);} 
  0% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -ms-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg);} 
  18% { -webkit-transform: rotate(-105deg); -moz-transform: rotate(-105deg); -ms-transform: rotate(-105deg); -o-transform: rotate(-105deg); transform: rotate(-105deg);} 
  14% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -ms-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg);} 
}

@-webkit-keyframes machineLid {
  100% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -ms-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg);} 
  43% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -ms-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg);} 
  39% { -webkit-transform: rotate(-105deg); -moz-transform: rotate(-105deg); -ms-transform: rotate(-105deg); -o-transform: rotate(-105deg); transform: rotate(-105deg);} 
  0% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -ms-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg);} 
  18% { -webkit-transform: rotate(-105deg); -moz-transform: rotate(-105deg); -ms-transform: rotate(-105deg); -o-transform: rotate(-105deg); transform: rotate(-105deg);} 
  14% { -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -ms-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg);} 
}

@keyframes filter {
  100% { -webkit-transform: translate( 0px , 0px );} 
  90% { -webkit-transform: translate( 0px , 0px ); -moz-transform: translate( 0px , 0px ); -ms-transform: translate( 0px , 0px ); -o-transform: translate( 0px , 0px ); transform: translate( 0px , 0px );} 
  86% { -webkit-transform: translate( 0px , -45px ); -moz-transform: translate( 0px , -45px ); -ms-transform: translate( 0px , -45px ); -o-transform: translate( 0px , -45px ); transform: translate( 0px , -45px );} 
  82% { -webkit-transform: translate( -75px , -45px ); -moz-transform: translate( -75px , -45px ); -ms-transform: translate( -75px , -45px ); -o-transform: translate( -75px , -45px ); transform: translate( -75px , -45px );} 
  36% { -webkit-transform: translate( -75px , -45px ); -moz-transform: translate( -75px , -45px ); -ms-transform: translate( -75px , -45px ); -o-transform: translate( -75px , -45px ); transform: translate( -75px , -45px );} 
  32% { -webkit-transform: translate( 60px , -45px ); -moz-transform: translate( 60px , -45px ); -ms-transform: translate( 60px , -45px ); -o-transform: translate( 60px , -45px ); transform: translate( 60px , -45px );} 
  28% { -webkit-transform: translate( 60px , 0px ); -moz-transform: translate( 60px , 0px ); -ms-transform: translate( 60px , 0px ); -o-transform: translate( 60px , 0px ); transform: translate( 60px , 0px );} 
  14% { -webkit-transform: translate( 60px , 0px ); -moz-transform: translate( 60px , 0px ); -ms-transform: translate( 60px , 0px ); -o-transform: translate( 60px , 0px ); transform: translate( 60px , 0px );} 
  10% { -webkit-transform: translate( 0px , 0px ); -moz-transform: translate( 0px , 0px ); -ms-transform: translate( 0px , 0px ); -o-transform: translate( 0px , 0px ); transform: translate( 0px , 0px );} 
  0% { -webkit-transform: translate( 0px , 0px ); -moz-transform: translate( 0px , 0px ); -ms-transform: translate( 0px , 0px ); -o-transform: translate( 0px , 0px ); transform: translate( 0px , 0px );} 
}

@-webkit-keyframes filter {
  100% { -webkit-transform: translate( 0px , 0px );} 
  90% { -webkit-transform: translate( 0px , 0px ); -moz-transform: translate( 0px , 0px ); -ms-transform: translate( 0px , 0px ); -o-transform: translate( 0px , 0px ); transform: translate( 0px , 0px );} 
  86% { -webkit-transform: translate( 0px , -45px ); -moz-transform: translate( 0px , -45px ); -ms-transform: translate( 0px , -45px ); -o-transform: translate( 0px , -45px ); transform: translate( 0px , -45px );} 
  82% { -webkit-transform: translate( -75px , -45px ); -moz-transform: translate( -75px , -45px ); -ms-transform: translate( -75px , -45px ); -o-transform: translate( -75px , -45px ); transform: translate( -75px , -45px );} 
  36% { -webkit-transform: translate( -75px , -45px ); -moz-transform: translate( -75px , -45px ); -ms-transform: translate( -75px , -45px ); -o-transform: translate( -75px , -45px ); transform: translate( -75px , -45px );} 
  32% { -webkit-transform: translate( 60px , -45px ); -moz-transform: translate( 60px , -45px ); -ms-transform: translate( 60px , -45px ); -o-transform: translate( 60px , -45px ); transform: translate( 60px , -45px );} 
  28% { -webkit-transform: translate( 60px , 0px ); -moz-transform: translate( 60px , 0px ); -ms-transform: translate( 60px , 0px ); -o-transform: translate( 60px , 0px ); transform: translate( 60px , 0px );} 
  14% { -webkit-transform: translate( 60px , 0px ); -moz-transform: translate( 60px , 0px ); -ms-transform: translate( 60px , 0px ); -o-transform: translate( 60px , 0px ); transform: translate( 60px , 0px );} 
  10% { -webkit-transform: translate( 0px , 0px ); -moz-transform: translate( 0px , 0px ); -ms-transform: translate( 0px , 0px ); -o-transform: translate( 0px , 0px ); transform: translate( 0px , 0px );} 
  0% { -webkit-transform: translate( 0px , 0px ); -moz-transform: translate( 0px , 0px ); -ms-transform: translate( 0px , 0px ); -o-transform: translate( 0px , 0px ); transform: translate( 0px , 0px );} 
}

@keyframes grounds {
  100% { -webkit-transform: translate( 0px , 0px ) rotate(0deg); -moz-transform: translate( 0px , 0px ) rotate(0deg); -ms-transform: translate( 0px , 0px ) rotate(0deg); -o-transform: translate( 0px , 0px ) rotate(0deg); transform: translate( 0px , 0px ) rotate(0deg);} 
  28% { -webkit-transform: translate( 0px , 0px ) rotate(0deg); -moz-transform: translate( 0px , 0px ) rotate(0deg); -ms-transform: translate( 0px , 0px ) rotate(0deg); -o-transform: translate( 0px , 0px ) rotate(0deg); transform: translate( 0px , 0px ) rotate(0deg);} 
  24% { -webkit-transform: translate(0px,-30px) rotate(-100deg);} 
  18% { -webkit-transform: translate(0px,-30px) rotate(-100deg);} 
  14% { -webkit-transform: translate( 0px , 0px ) rotate(0deg); -moz-transform: translate( 0px , 0px ) rotate(0deg); -ms-transform: translate( 0px , 0px ) rotate(0deg); -o-transform: translate( 0px , 0px ) rotate(0deg); transform: translate( 0px , 0px ) rotate(0deg);} 
  0% { } 
}

@-webkit-keyframes grounds {
  100% { -webkit-transform: translate( 0px , 0px ) rotate(0deg); -moz-transform: translate( 0px , 0px ) rotate(0deg); -ms-transform: translate( 0px , 0px ) rotate(0deg); -o-transform: translate( 0px , 0px ) rotate(0deg); transform: translate( 0px , 0px ) rotate(0deg);} 
  28% { -webkit-transform: translate( 0px , 0px ) rotate(0deg); -moz-transform: translate( 0px , 0px ) rotate(0deg); -ms-transform: translate( 0px , 0px ) rotate(0deg); -o-transform: translate( 0px , 0px ) rotate(0deg); transform: translate( 0px , 0px ) rotate(0deg);} 
  24% { -webkit-transform: translate(0px,-30px) rotate(-100deg);} 
  18% { -webkit-transform: translate(0px,-30px) rotate(-100deg);} 
  14% { -webkit-transform: translate( 0px , 0px ) rotate(0deg); -moz-transform: translate( 0px , 0px ) rotate(0deg); -ms-transform: translate( 0px , 0px ) rotate(0deg); -o-transform: translate( 0px , 0px ) rotate(0deg); transform: translate( 0px , 0px ) rotate(0deg);} 
  0% { } 
}

@keyframes groundsPour {
  100% { width: 0px; margin-left: 10px;} 
  27% { width: 0px; margin-left: 10px;} 
  23% { width: 0px; margin-left: -33px;} 
  19% { width: 43px; margin-left: -33px;} 
  15% { width: 0px; margin-left: 10px;} 
  0% { width: 0px; margin-left: 10px;} 
}

@-webkit-keyframes groundsPour {
  100% { width: 0px; margin-left: 10px;} 
  27% { width: 0px; margin-left: 10px;} 
  23% { width: 0px; margin-left: -33px;} 
  19% { width: 43px; margin-left: -33px;} 
  15% { width: 0px; margin-left: 10px;} 
  0% { width: 0px; margin-left: 10px;} 
}

@keyframes groundsPourGrounds {
  100% { margin-right: 0px;} 
  27% { margin-right: -43px;} 
  23% { margin-right: -43px;} 
  19% { margin-right: 0px;} 
  15% { margin-right: 0px;} 
  0% { margin-right: 0px;} 
}

@-webkit-keyframes groundsPourGrounds {
  100% { margin-right: 0px;} 
  27% { margin-right: -43px;} 
  23% { margin-right: -43px;} 
  19% { margin-right: 0px;} 
  15% { margin-right: 0px;} 
  0% { margin-right: 0px;} 
}

@keyframes waterPour {
  100% { height: 0px; margin-top: -75px;} 
  37% { height: 0px; margin-top: -75px;} 
  33% { height: 60px; margin-top: -135px;} 
  26% { height: 60px; margin-top: -135px;} 
  0% { height: 0px; margin-top: -135px;} 
  18% { height: 0px; margin-top: -135px;} 
}

@-webkit-keyframes waterPour {
  100% { height: 0px; margin-top: -75px;} 
  37% { height: 0px; margin-top: -75px;} 
  33% { height: 60px; margin-top: -135px;} 
  26% { height: 60px; margin-top: -135px;} 
  0% { height: 0px; margin-top: -135px;} 
  18% { height: 0px; margin-top: -135px;} 
}

@keyframes water {
  100% { margin-top: -60px;} 
  37% { margin-top: -60px;} 
  33% { margin-top: 0px;} 
  26% { margin-top: 0px;} 
  0% { margin-top: 0px;} 
  18% { margin-top: 0px;} 
}

@-webkit-keyframes water {
  100% { margin-top: -60px;} 
  37% { margin-top: -60px;} 
  33% { margin-top: 0px;} 
  26% { margin-top: 0px;} 
  0% { margin-top: 0px;} 
  18% { margin-top: 0px;} 
}

@keyframes espresso {
  100% { height: 0px; margin-top: 18px;} 
  70% { height: 0px; margin-top: 18px;} 
  67% { height: 18px; margin-top: 0px;} 
  55% { height: 18px; margin-top: 0px;} 
  52% { height: 0px; margin-top: 0px;} 
  0% { height: 0px; margin-top: 0px;} 
}

@-webkit-keyframes espresso {
  100% { height: 0px; margin-top: 18px;} 
  70% { height: 0px; margin-top: 18px;} 
  67% { height: 18px; margin-top: 0px;} 
  55% { height: 18px; margin-top: 0px;} 
  52% { height: 0px; margin-top: 0px;} 
  0% { height: 0px; margin-top: 0px;} 
}

@keyframes greenlight {
  100% { opacity: 0.25;} 
  70% { opacity: 0.25;} 
  67% { opacity: 1;} 
  52% { opacity: 1;} 
  49% { opacity: 0.25;} 
  0% { opacity: 0.25;} 
}

@-webkit-keyframes greenlight {
  100% { opacity: 0.25;} 
  70% { opacity: 0.25;} 
  67% { opacity: 1;} 
  52% { opacity: 1;} 
  49% { opacity: 0.25;} 
  0% { opacity: 0.25;} 
}

@keyframes redlight {
  100% { opacity: 1;} 
  70% { opacity: 1;} 
  67% { opacity: 0.25;} 
  52% { opacity: 0.25;} 
  49% { opacity: 1;} 
  0% { opacity: 1;} 
}

@-webkit-keyframes redlight {
  100% { opacity: 1;} 
  70% { opacity: 1;} 
  67% { opacity: 0.25;} 
  52% { opacity: 0.25;} 
  49% { opacity: 1;} 
  0% { opacity: 1;} 
}