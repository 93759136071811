
.bean-rain {
    position: fixed;
    top: 0;
    left: 0;
    width: 20%;
    height: 20%;
    background-image: url('https://storage.googleapis.com/app_darkendimg/assets/brkoin.png');
    background-size: contain;
    background-repeat: repeat;
    animation: rainAnimation 2s linear forwards;
  }

  .centered-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white; /* or any color you'd like for the text */
    z-index: 1; /* to appear above the bean rain */
  }
  
  @keyframes rainAnimation {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 0% 100%;
    }
  }
  